<template>
  <div class="main-body">
    <div class="card-item">
      <div class="order-attention">
        <p>微信小程序设置</p>
      </div>
      <a-divider />
      <div class="action-body">
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wxapp/config?appid=')">
            <WechatOutlined class="action-icon" />
            <span>
              <div class="title">小程序管理</div>
              <div class="desc">设置参数</div>
            </span>
          </div>
        </div>
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wxapp/config?appid=')">
            <AlertOutlined class="action-icon" />
            <span>
              <div class="title">小程序发布</div>
              <div class="desc">发布小程序</div>
            </span>
          </div>
        </div>
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wxapp/config?appid=')">
            <BellOutlined class="action-icon" />
            <span>
              <div class="title">订阅消息</div>
              <div class="desc">消息订阅</div>
            </span>
          </div>
        </div>
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wxapp/config?appid=')">
            <ShareAltOutlined class="action-icon" />
            <span>
              <div class="title">分享设置</div>
              <div class="desc">内容自定义</div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-item">
      <div class="order-attention">
        <p>微信小程序使用流程</p>
      </div>
      <a-divider />
      <a-steps :current="5" size="small">
        <a-step title="注册微信小程序" />
        <a-step title="信息完善" />
        <a-step title="开发上传代码" />
        <a-step title="提交审核" />
        <a-step title="发布小程序" />
      </a-steps>
      <div class="weixin-url">
        <span>微信公众平台地址：</span>
        <a href="https://mp.weixin.qq.com" target="_blank"
          >https://mp.weixin.qq.com</a
        >
      </div>
      <div class="weixin-url">
        <span>微信开放平台地址：</span>
        <a href="https://open.weixin.qq.com" target="_blank"
          >https://open.weixin.qq.com</a
        >
      </div>
      <div class="weixin-url">
        <span>微信商户平台地址：</span>
        <a href="https://pay.weixin.qq.com" target="_blank"
          >https://pay.weixin.qq.com</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import {
  WechatOutlined,
  AlertOutlined,
  BellOutlined,
  ShareAltOutlined,
} from "@ant-design/icons-vue";
import { useRouter,useRoute } from "vue-router";
export default defineComponent({
  components: {
    WechatOutlined,
    AlertOutlined,
    BellOutlined,
    ShareAltOutlined,
  },
  setup() {
    const route = useRoute();
    const appid=route.query.appid
    const router = useRouter();
    const goTo = (url) => {
      router.push(url+appid);
    };
    return { goTo };
  },
});
</script>
<style scoped lang="less">
.card-item {
  margin-bottom: 50px;
  .action-body {
    display: flex;
    flex-wrap: wrap;
    .action-item {
      width: 20%;
      .action-item-btn {
        display: flex;
        background-color: #f0f2f5;
        margin: 20px;
        padding: 10px;
        border-radius: 3px;
        cursor: pointer;
        .action-icon {
          font-size: 36px;
          margin: 6px 10px;
          color: #52c41a;
        }
        .title {
          font-size: 14px;
        }
        .desc {
          color: #999999;
        }
      }
    }
  }
  .weixin-url {
    margin: 20px 0;
  }
}
</style>